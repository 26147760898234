@font-face {
  font-family: "flaticon";
  src: url("../Fonts/flaticon.woff") format("woff"),
    url("../Fonts//flaticon.woff2") format("woff2");
}

i[class^="flaticon-"]:before,
i[class*=" flaticon-"]:before {
  font-family: flaticon !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-search:before {
  content: "\f101";
}
.flaticon-facebook-circular-logo:before {
  content: "\f102";
}
.flaticon-twitter:before {
  content: "\f103";
}
.flaticon-linkedin:before {
  content: "\f104";
}
.flaticon-chalet:before {
  content: "\f105";
}
.flaticon-bus:before {
  content: "\f106";
}
.flaticon-abc-block:before {
  content: "\f107";
}
.flaticon-agriculture:before {
  content: "\f108";
}
.flaticon-people:before {
  content: "\f109";
}
.flaticon-bar-chart:before {
  content: "\f10a";
}
.flaticon-building:before {
  content: "\f10b";
}
.flaticon-money:before {
  content: "\f10c";
}
.flaticon-arab:before {
  content: "\f10d";
}
.flaticon-id-card-for-office:before {
  content: "\f10e";
}
.flaticon-anti-age:before {
  content: "\f10f";
}
.flaticon-email:before {
  content: "\f110";
}
.flaticon-phone-call:before {
  content: "\f111";
}
.flaticon-placeholder:before {
  content: "\f112";
}
.flaticon-clock:before {
  content: "\f113";
}
.flaticon-vote:before {
  content: "\f114";
}
.flaticon-vision:before {
  content: "\f115";
}
.flaticon-arrow-up:before {
  content: "\f116";
}
.flaticon-admin:before {
  content: "\f117";
}
.flaticon-love:before {
  content: "\f118";
}
.flaticon-growth:before {
  content: "\f119";
}
.flaticon-rub-el-hizb:before {
  content: "\f11a";
}
.flaticon-goal:before {
  content: "\f11b";
}
.flaticon-house:before {
  content: "\f11c";
}
